import { CircularProgress, IconButton } from '@material-ui/core'
import { Favorite, FavoriteBorder } from '@material-ui/icons'
import { useContext, useState } from 'react'
import { elementType, object, string } from 'prop-types'
import { useCurrentCustomer } from '@bloomreach/connector-components-react'
import { useMutation, useQuery } from '@apollo/client'
import clsx from 'clsx'
import { getLoginStatus } from '../../../../lib/utils'
import useStyles from './style'
import WISHLIST_QUERY from '../../../_Hooks/useWishlist/WISHLIST_QUERY'
import ADD_ENTRY_TO_WISHLIST_MUTATION from '../../../_Hooks/useWishlist/ADD_ENTRY_TO_WISHLIST_MUTATION'
import REMOVE_ENTRY_FROM_WISHLIST_MUTATION from '../../../_Hooks/useWishlist/REMOVE_ENTRY_FROM_WISHLIST_MUTATION'
import { GoogleEnhancedEcommerce } from '../../../_Modules/ThirdPartyScripts/GoogleEnhancedEcommerce'

const WishlistButton = ({
    className, component: Component, product, ...props
}) => {
    const { prepareProductForDataLayer, pushAddToWishList } = useContext(GoogleEnhancedEcommerce)
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const { data } = useQuery(WISHLIST_QUERY, {
        onCompleted: () => { if (loading) setLoading(() => false) }
    })
    const wishlist = data?.findWishlists.wishlists[0] ?? {}

    const productEntry = wishlist?.entries?.find((e) => e?.items?.some((i) => i.itemId.id.includes(product.itemId.id)))
    const productIsInWishlist = !!productEntry

    const [addProductToWishlist] = useMutation(ADD_ENTRY_TO_WISHLIST_MUTATION, {
        variables: {
            wishlistId: wishlist.id,
            itemId: product.itemId.id
            // customAttrs: product.customAttrs?.map(({ __typename, ...attrs }) => attrs)
        },
        refetchQueries: [WISHLIST_QUERY]
    })

    const [removeProductFromWishlist] = useMutation(REMOVE_ENTRY_FROM_WISHLIST_MUTATION, {
        variables: {
            wishlistId: wishlist.id,
            entryId: productEntry?.id
        },
        refetchQueries: [WISHLIST_QUERY]
    })

    const onClick = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (loading) return
        setLoading(() => true)

        if (productIsInWishlist) {
            await removeProductFromWishlist()
        } else {
            await addProductToWishlist()
            pushAddToWishList(prepareProductForDataLayer(product))
        }
    }

    return (
        <Component
            {...props}
            onClick={onClick}
            disabled={loading}
            className={clsx(classes.wrapper, className)}
        >
            {productIsInWishlist
                ? <Favorite />
                : <FavoriteBorder />}
            {loading && (
                <div className={classes.loadingSpinner}>
                    <CircularProgress size={30} />
                </div>
            )}
        </Component>
    )
}

const WishlistButtonAuthProxy = (props) => {
    const [user] = useCurrentCustomer()

    if (getLoginStatus(user) === 'Guest') return null

    return <WishlistButton {...props} />
}

const propTypes = {
    component: elementType,
    product: object.isRequired,
    className: string
}

const defaultProps = {
    component: IconButton,
    className: undefined
}

WishlistButton.propTypes = propTypes
WishlistButton.defaultProps = defaultProps
WishlistButtonAuthProxy.protoTypes = propTypes
WishlistButtonAuthProxy.defaultProps = defaultProps

export default WishlistButtonAuthProxy
