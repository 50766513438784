import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import {
    useCallback, useContext, useEffect, useMemo, useRef, useState
} from 'react'
import {
    CircularProgress,
    Container,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    LinearProgress,
    NoSsr,
    Paper,
    Typography
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import clsx from 'clsx'
import { BrPageContext } from '@bloomreach/react-sdk'
import useStyles from './style'
import Location from './Location/Location'
import useTranslation from '../../_Elements/ResourceBundles/useTranslation'

const containerStyle = {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
}

const libraries = ['places']

const StorelocatorComponent = () => {
    const t = useTranslation()
    const classes = useStyles()
    const page = useContext(BrPageContext)

    const {
        googleMapsApiKey
    } = page?.getChannelParameters() ?? {}

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey,
        libraries
    })

    const [map, setMap] = useState(null)
    const placesService = useMemo(() => (map
        ? new window.google.maps.places.PlacesService(map)
        : undefined),
    [map])

    const [searchValue, setSearchValue] = useState('')

    const placesRef = useRef(null)
    const [places, setPlaces] = useState(null)
    const [activePlace, setActivePlace] = useState(null)

    const [activePlaceDetails, setActivePlaceDetails] = useState(null)
    const [isLoadingActivePlaceDetails, setIsLoadingActivePlaceDetails] = useState(false)

    const boundsChangeRef = useRef()
    const [placesLoading, setPlacesLoading] = useState(false)
    const onBoundsChange = useCallback(() => {
        if (!placesService) return

        if (boundsChangeRef.current) {
            clearTimeout(boundsChangeRef.current)
            boundsChangeRef.current = undefined
        }
        setPlacesLoading(() => true)
        boundsChangeRef.current = setTimeout(() => {
            placesService.textSearch({
                query: 'Baldinini',
                bounds: map.getBounds(),
                type: ['store']
            }, (newPlaces) => {
                setPlaces((pp) => ({
                    ...pp,
                    ...Object.fromEntries(newPlaces
                        .filter(((pl) => {
                            const placeTypes = pl.types
                            return (placeTypes.includes('shoe_store') || placeTypes.includes('clothing_store') || placeTypes.includes('shopping_mall'))
                        }))
                        .map((p) => [p.place_id, p]))
                }))
                setPlacesLoading(() => false)
            })
        }, 500)
    }, [placesService, activePlace])

    const onLoad = useCallback(async (_map) => {
        const errLat = 49.7252464
        const errLng = 16.2891641
        const initialLocation = (lat, lng, zoom = 5) => {
            const location = new window.google.maps.LatLng(lat, lng)
            _map.setZoom(zoom)
            _map.setCenter(location)
            setMap(_map)
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                initialLocation(position.coords.latitude, position.coords.longitude, 7)
            }, () => {
                initialLocation(errLat, errLng)
            })
        } else {
            initialLocation(errLat, errLng)
        }
    }, [])

    useEffect(() => {
        if (placesRef.current && activePlace) {
            placesRef.current.scrollTop = 0
        }
    }, [activePlace])

    const onUnmount = useCallback(() => {
        setMap(null)
    }, [])

    useEffect(() => {
        if (!(placesService && activePlace)) {
            setActivePlaceDetails(null)
            setIsLoadingActivePlaceDetails(false)

            return
        }

        setIsLoadingActivePlaceDetails(true)

        placesService.getDetails({
            placeId: activePlace.place_id,
            fields: ['website', 'name', 'business_status', 'opening_hours', 'international_phone_number', 'utc_offset_minutes']
        }, (pD, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && pD) {
                setActivePlaceDetails(pD)
            }

            setIsLoadingActivePlaceDetails(false)
        })
    }, [activePlace?.place_id])

    const onPlaceClick = (place) => {
        setActivePlace(() => place)
        map.setZoom(10)
        map.panTo(place.geometry.location)
    }

    const placesinView = useMemo(() => (places && map
        ? Object.values(places).filter((place) => map.getBounds().contains(place.geometry.location))
        : null),
    [map, places])

    const handleSearch = () => {
        placesService.findPlaceFromQuery({
            query: searchValue,
            fields: ['geometry.viewport']
        }, (place) => {
            if (place[0]) {
                map.fitBounds(place[0].geometry.viewport)
            }
        })
    }

    if (!isLoaded) return null

    return (
        <Container
            className={classes.storelocator}
            maxWidth='xl'
        >
            <h2>
                {t('storelocator.title')}
            </h2>
            <Grid
                className={classes.container}
                container
                direction='row'
            >
                { !map && (
                    <div
                        className={classes.loadingOverlay}
                    >
                        <CircularProgress />
                    </div>
                )}
                <Grid
                    md={3}
                    item
                    container
                    direction='column'
                >
                    <Grid
                        className={classes.searchContainer}
                        item
                        xs
                    >
                        <FormControl
                            fullWidth
                        >
                            <InputLabel htmlFor='location-search'>
                                { t('storelocator.search') }
                            </InputLabel>
                            <Input
                                id='location-search'
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch()
                                    }
                                }}
                                fullWidth
                                value={searchValue}
                                onChange={(e) => {
                                    e.persist()
                                    setSearchValue(() => e.target.value)
                                }}
                                endAdornment={(
                                    <InputAdornment position='end'>
                                        <IconButton
                                            onClick={handleSearch}
                                        >
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                )}
                            />
                        </FormControl>
                        { placesLoading && (
                            <LinearProgress
                                variant='query'
                                className={classes.linearProgress}
                            />
                        )}
                    </Grid>
                    <Grid
                        ref={placesRef}
                        className={classes.places}
                        item
                        xs
                    >
                        {/* INITIAL LOADING  */}
                        { (!!map && !places) && (
                            <div
                                className={classes.loadingOverlay}
                            >
                                <CircularProgress />
                            </div>
                        )}

                        {placesinView?.map((place) => (
                            <Location
                                activePlaceId={activePlace?.place_id}
                                key={place.place_id}
                                place={place}
                                onClick={() => { onPlaceClick(place) }}
                                placeDetail={activePlaceDetails}
                                isLoadingPlaceDetail={isLoadingActivePlaceDetails}
                            />
                        ))}

                        { ((!placesinView || placesinView.length === 0) && !placesLoading && map) && (
                            <Typography
                                align='center'
                            >
                                { t('storelocator.noLocationsFound') }
                            </Typography>
                        )}
                    </Grid>

                </Grid>
                <Grid
                    md={9}
                    item
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs
                    >
                        <Paper
                            variant='outlined'
                            className={classes.mapContainer}
                        >
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                                onBoundsChanged={onBoundsChange}
                            >
                                { placesinView?.map((place) => (
                                    <Marker
                                        icon={activePlace?.place_id === place.place_id ? '/markerActive.svg' : '/marker.svg'}
                                        key={place.place_id}
                                        position={place.geometry.location}
                                        onClick={() => { onPlaceClick(place) }}
                                    />
                                )) ?? null }
                            </GoogleMap>
                            { placesLoading && (
                                <LinearProgress
                                    variant='query'
                                    className={clsx(classes.linearProgress, classes.linearProgressTop)}
                                />
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

const Storelocator = () => (
    <NoSsr>
        <StorelocatorComponent />
    </NoSsr>
)

export default Storelocator
