import {
    useContext, useMemo, useState
} from 'react'
import { func, shape } from 'prop-types'
import { useCookies } from 'react-cookie'
import Image from 'next/image'
import { useProductDetail } from '@bloomreach/connector-components-react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    Grid, useMediaQuery
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import Carousel from 'react-multi-carousel'
import {
    getConnector, getSlugFromUrl, getSmViewId, getVarAttrs, availableSizes, stringToArr
} from '../../../../lib/utils'

import AddToCartButton from '../../Cart/AddToCart/AddToCartButton'
import LoginContext from '../../Account/Login/LoginContext'
import useStyles from './style'
import DisplayPrice from '../DisplayPrice/DisplayPrice'
import ColorSwatch from '../ColorSwatch/ColorSwatch'
import ProductSize from '../ProductSize/ProductSize'
import { COOKIE_LANGUAGE, COOKIE_LOCATION } from '../../../_Mappings/cookies'
import storeKeys from '../../../_Mappings/storeKeys'
import useSizeList from '../ProductSize/components/useSizeList'
import warehouseMap from '../../../_Mappings/warehouseMap'
import useChannelKeyWorkaround from '../../Cart/useChannelKeyWorkaround'
import channelKeys from '../../../_Mappings/channelKeys'
import useTrackProductDetail from '../../../_Hooks/HeroTracker/useTrackProductDetail'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import PromotionBadges from '../../../_Elements/PromotionBadges/PromotionBadges'
import theme from '../../../../lib/theme'
import useGoogleProductDetail from '../../../_Hooks/GoogleAnalytics/useGoogleProductDetail'
import ColorSwatchSku from '../ColorSwatch/ColorSwatchSku'
import WishlistButton from '../../../_Elements/Wishlist/WishlistButton/WishlistButton'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import RelatedSlider from '../../ProductSlider/RelatedSlider'
import Scalapay from '../../../_Modules/Scalapay/Scalapay'
import { PayPalScriptProvider, PayPalMessages } from "@paypal/react-paypal-js"
import getConfig from 'next/config'
import { SFMCCollectJs } from '../../ThirdPartyScripts/SFMCCollectJs'
import { useEffect } from 'react'
import Head from 'next/head'
import languages from '../../../_Mappings/languages'

const ProductDetail = ({ page }) => {
    const classes = useStyles()
    const { md } = theme.breakpoints.values
    const isDesktop = useMediaQuery((thm) => thm.breakpoints.up('md'))
    const connector = getConnector(page)
    const smViewId = getSmViewId(page)
    const { cartId } = useContext(LoginContext)
    const { pushItemView } = useContext(SFMCCollectJs)
    const translations = useTranslation()
    const productImageLoader = ({ src, width }) => src
    const [cookies] = useCookies([COOKIE_LOCATION, '_br_uid_2'])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const brUid2 = cookies?._br_uid_2
    const { storeKey, currency } = storeKeys[location] || {}
    const warehouse = warehouseMap[location]
    const language = cookies[COOKIE_LANGUAGE] ?? 'en'
    const paypalLocale = languages.find(({ value }) => value === language)?.header
    // TODO: remove when bloomreach implements channelKey as param
    const distributionChannel = channelKeys[storeKey]
    const distributionChannelWorkaround = useChannelKeyWorkaround(distributionChannel)

    // Pulling the Product Code from URL is not great and unsafe. Please change, whenever there's time.
    const slug = getSlugFromUrl(page.getUrl())
    const customAttrFields = ['brand', 'thumb_image']
    const customVariantAttrFields = ['sku_thumb_images', 'sku_swatch_images']

    const [item] = useProductDetail({
        connector,
        customAttrFields,
        customVariantAttrFields,
        smViewId,
        slug,
        brUid2,
        storeKey
    })

    const attributesByVariants = useMemo(() => item?.variants.map((variant) => ({
        ...variant,
        _mappedAttributes: getVarAttrs(variant)
    })), [item?.itemId.id])

    const sku = useMemo(() => item?.itemId?.code?.split('-')[0], [item?.itemId.code])
    const attributes = useMemo(() => (
        item ? getVarAttrs(item.variants[0]) : null
    ), [item?.itemId.id])

    // PRODUCT SIZES
    const productSizes = useMemo(() => attributesByVariants?.filter(({ _mappedAttributes: { size } }) => size), [attributesByVariants])
    const [activeSizeIndex, setActiveSizeIndex] = useState(null)

    const colors = useMemo(() => (attributes ? [{
        colour1Hex: attributes.colour1Hex,
        colour2Hex: attributes.colour2Hex
    }] : null), [attributes])

    //const sizeList = useSizeList(attributesByVariants)

    const [successMessage, setSuccessMessage] = useState(null)

    const maximumNumberOfProducts = 3
    const analyticsCategories = attributes?.analyticsCategories?.split('|')
    const colorName = attributes?.colour1Text?.[language]
    const query = analyticsCategories?.length > 0 ? analyticsCategories[analyticsCategories.length - 1] + ' ' + colorName : colorName
    const sliderTitle = translations('product:related.products')

    useTrackProductDetail(item)
    useGoogleProductDetail(item)
    useEffect( () => {
        if(sku){
            pushItemView(sku)
        }
    }, [sku]
    )

    const { publicRuntimeConfig } = getConfig()

    const payPalClientId = publicRuntimeConfig.payPalClientId

    const accordions = useMemo(() => (
        attributes
            ? [
                {
                    name: 'description',
                    content: (
                        !!(attributes.descriptionTechnical || attributes.descriptionEmotional) && (
                            <Typography variant='caption'>
                                {attributes.descriptionTechnical?.[language]}
                                {attributes.descriptionTechnical?.[language] && (
                                    <>
                                        <br />
                                        <br />
                                    </>
                                )}
                                {attributes.descriptionEmotional?.[language]}
                            </Typography>
                        )
                    )
                },
                {
                    name: 'measures',
                    content: !!((attributes.soleLength?.[language]
                        || attributes.legHeight?.[language]
                        || attributes.jacketMeasures?.[language]
                        || attributes.bagsMeasures?.[language])) && (
                            <Typography
                                variant='caption'
                                dangerouslySetInnerHTML={{
                                    __html: [
                                        attributes.heelHeight?.[language]
                                        && `${translations('product:heelhight')}: ${attributes.heelHeight?.[language]}`,
                                        attributes.soleLength?.[language]
                                        && `${translations('product:sole.length')}: ${attributes.soleLength?.[language]}`,
                                        attributes.legHeight?.[language]
                                        && `${translations('product:leg.length')}: ${attributes.legHeight?.[language]}`,
                                        attributes.jacketMeasures?.[language]
                                        && `${translations('product:jacket.measures')}: ${attributes.jacketMeasures?.[language]}`,
                                        attributes.bagsMeasures?.[language]
                                        && `${translations('product:bag.measures')}: ${attributes.bagsMeasures?.[language]}`
                                    ].filter(Boolean).join('<br /><br />')
                                }}
                            />
                        )
                },
                {
                    name: 'detailCare',
                    content: !!((attributes.detailCare?.[language])) && (
                        <Typography
                            variant='caption'
                            dangerouslySetInnerHTML={{
                                __html: [
                                    attributes.detailCare?.[language]
                                    && `${attributes.detailCare?.[language]}`
                                ].filter(Boolean).join('<br /><br />')
                            }}
                        />
                    )
                }
            ]
            : []
    ), [attributes])

    const colorVariantsSku = useMemo(() => stringToArr(attributes?.colourVariants), [attributes])
    const relatedProductsIds = useMemo(() => stringToArr(attributes?.relatedOpen)?.slice(0,4), [attributes])

    if (!item) return null

    return (
        <><Head>
            <title>Baldinini - {item?.displayName}</title>
            <meta name="description" content={attributes.upper?.[language] + ' - ' +
                attributes.closing?.[language] + ' - ' +
                attributes.lining?.[language] + ' - ' +
                attributes.innerSole?.[language] + ' - ' +
                attributes.outSole?.[language] + ' - ' +
                attributes.heelDescription?.[language] + ' - ' +
                attributes.tipType?.[language] + ' - ' +
                attributes.madeIn?.[language]} />
            <script
                key="structured-data"
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(
                        {
                            '@context': 'https://schema.org',
                            '@type': 'Product',
                            "name": item?.displayName,
                            "description": attributes.upper?.[language] + ' - ' +
                                attributes.closing?.[language] + ' - ' +
                                attributes.lining?.[language] + ' - ' +
                                attributes.innerSole?.[language] + ' - ' +
                                attributes.outSole?.[language] + ' - ' +
                                attributes.heelDescription?.[language] + ' - ' +
                                attributes.tipType?.[language] + ' - ' +
                                attributes.madeIn?.[language],
                            "image": [
                                item.imageSet.variants.map(({ image }) => (image.link.href))
                            ],
                            "sku": sku,
                            "brand": {
                                "@type": "Brand",
                                "name": "Baldinini"
                            },
                        }
                    )
                }} />
        </Head><Grid container>
                <Grid
                    container
                    item
                    md={8}
                    spacing={1}
                >
                    {isDesktop
                        ? item.imageSet.variants.map((({ image, name }, i) => (
                            <Grid
                                key={name}
                                item
                                md={i === 0 ? 12 : 6}
                            >
                                {i === 0 && <div className={classes.offsetTop} />}
                                <Box className={classes.imgContainer}>
                                    <Image
                                        height={768}
                                        width={768}
                                        quality={85}
                                        priority
                                        src={image.link.href}
                                        loader={productImageLoader} />
                                    <div className={classes.imgOverlay} />
                                </Box>
                            </Grid>
                        )))
                        : (
                            <Grid
                                item
                                xs={12}
                            >
                                <div className={classes.offsetTop} />

                                <Carousel
                                    className={classes.productSlider}
                                    additionalTransfrom={0}
                                    minimumTouchDrag={80}
                                    containerClass='container'
                                    arrows
                                    draggable
                                    keyBoardControl
                                    renderButtonGroupOutside
                                    focusOnSelect={false}
                                    showDots
                                    responsive={{
                                        mobile: {
                                            breakpoint: {
                                                max: md,
                                                min: 0
                                            },
                                            items: 1
                                        }
                                    }}
                                >
                                    {item.imageSet.variants.map((({ image, name }) => (
                                        <Box
                                            className={classes.imgContainer}
                                            key={name}
                                        >
                                            <Image
                                                height={768}
                                                width={768}
                                                quality={85}
                                                priority
                                                src={image.link.href}
                                                loader={productImageLoader} 
                                                alt={item?.displayName + ' photo ' + image.index}
                                                title={item?.displayName} />
                                            <div className={classes.mobileImgOverlay} />
                                        </Box>
                                    )))}
                                </Carousel>
                            </Grid>
                        )}
                </Grid>
                <Grid
                    item
                    md={4}
                >
                    <div
                        className={classes.infoContainer}
                    >
                        <Typography
                            align='center'
                            variant='h5'
                            component='h1'
                        >
                            {item?.displayName}
                        </Typography>
                        <Typography
                            align='center'
                            className={classes.smallText}
                            dangerouslySetInnerHTML={{
                                __html: [
                                    attributes.upper?.[language],
                                    attributes.closing?.[language],
                                    attributes.lining?.[language],
                                    attributes.innerSole?.[language],
                                    attributes.outSole?.[language],
                                    attributes.heelDescription?.[language],
                                    attributes.tipType?.[language],
                                    attributes.madeIn?.[language]
                                ].filter(Boolean).join('&nbsp;&middot;&nbsp;')
                            }} />
                        <PromotionBadges
                            productVariant={item.variants[0]} />
                        <DisplayPrice item={item} />
                        {['IT', 'FR', 'ES', 'PT', 'DE', 'NL', 'AT', 'BE', 'FI'].includes(location) &&
                            <Grid container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                sm={12}>
                                <Typography
                                    align='center'
                                    className={classes.smallText}>
                                    <Scalapay></Scalapay>
                                </Typography>
                            </Grid>}

                        <Typography
                            align='center'
                            className={classes.smallText}
                        >
                            {sku}
                        </Typography>

                        <div className={classes.colorVariants}>
                            <ColorSwatch colors={colors} />
                            {colorVariantsSku?.map((variantSku, index) => <ColorSwatchSku key={`${variantSku}-${index.toString()}`} sku={variantSku} />)}
                        </div>

                        <Divider className={classes.divider} />

                        <ProductSize
                            sizeList={availableSizes(item)}
                            onChange={(value) => {
                                setActiveSizeIndex(() => productSizes.findIndex((ps) => String(ps._mappedAttributes.size) === value))
                            } }
                            value={productSizes[activeSizeIndex]} />
                        <Box
                            display='flex'
                            alignItems='center'
                        >
                            <AddToCartButton
                                sizeList={availableSizes(item)}
                                cartId={cartId}
                                item={item}
                                itemId={productSizes[activeSizeIndex]?.itemId ?? item.itemId}
                                warehouse={warehouse}
                                disabled={!(storeKey && warehouse)}
                                distributionChannel={distributionChannelWorkaround}
                                storeKey={storeKey}
                                setSuccessMessage={setSuccessMessage} />

                            <WishlistButton
                                product={item}
                                defaultPadding
                                variant='outlined'
                                component={BaseButton} />

                        </Box>

                        {successMessage && <Typography component='p' variant='root' align='center'>{successMessage}</Typography>}
                        <PayPalScriptProvider options={{ "client-id": payPalClientId, "currency": currency, "components": "messages", "enable-funding": "paylater", "data-namespace": "PayPalSDK", "locale": paypalLocale }}>
                            <PayPalMessages style={{ layout: "text" }} amount="29.00" placement="product" />
                        </PayPalScriptProvider>

                        {accordions.map((accordion) => {
                            const { name, content } = accordion
                            return (
                                content && (
                                    <Accordion
                                        key={name}
                                        className={classes.accordion}
                                        elevation={0}


                                    >
                                        <AccordionSummary expandIcon={<ExpandMore />}>
                                            <Typography variant='body2'>
                                                {translations(`product:${name}`)}
                                            </Typography>
                                        </AccordionSummary>

                                        <AccordionDetails>{content}</AccordionDetails>
                                    </Accordion>


                                )
                            )
                        })}
                    </div>
                </Grid>
                <Grid container item md={12}
                    spacing={1}>
                    <RelatedSlider
                        sliderTitle={sliderTitle}
                        maximumNumberOfProducts={maximumNumberOfProducts}
                        query={query}
                        itemsIds={relatedProductsIds}
                        brUid2={brUid2}
                        storeKey={storeKey}
                        connector={connector}
                        parentId={item.itemId.id} />
                </Grid>
            </Grid></>


    )
}

ProductDetail.propTypes = {
    page: shape({
        getUrl: func
    })
}

ProductDetail.defaultProps = {
    page: undefined
}

export default ProductDetail
